import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kanban mr-1"},[_c('div',{staticClass:"kanban-column",style:(("border-top: 8px " + (_vm.column.color) + " solid;"))},[_vm._t("column-header",function(){return [_c('div',{staticClass:"kanban-header"},[_c('small',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.column.name))])])]},{"data":{
        index: _vm.index,
        columnData: _vm.column,
      }}),_c('draggable',{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll.self",value:(_vm.listenScrollFetchMore),expression:"listenScrollFetchMore",modifiers:{"self":true}}],staticClass:"kanban-card-content",attrs:{"list":_vm.column.lists,"animation":200,"move":_vm.checkMove,"ghost-class":"ghost-card","group":"lists","delay":500,"delay-on-touch-only":true},on:{"change":function($event){return _vm.updateStatus($event, _vm.index)}}},_vm._l((_vm.column.lists),function(list){return _c('div',{key:list.id},[_vm._t("kanban-card",function(){return [_c(VCard,{key:list.id,staticClass:"mb-2"},[_c(VCardText,[_vm._v(" "+_vm._s(list.name)+" ")])],1)]},{"data":list})],2)}),0)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }