<template>
  <v-dialog
    v-model="isOpen"
    max-width="760px"
  >
    <v-card
      class="pa-4"
    >
      <div class="d-flex justify-space-between mb-4">
        <span class="text-h6 font-medium primary--text">Sortir Berdasarkan</span>
        <v-icon
          @click="close"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-form
        ref="jobSortForm"
        lazy-validation
        @submit.prevent="sort"
      >
        <draggable
          :list="jobSort"
          :animation="200"
        >
          <v-row
            v-for="(sort, index) in jobSort"
            :key="sort.field.value"
          >
            <v-col
              cols="12"
              md="6"
            >
              <div class="d-flex">
                <v-icon
                  class="cursor-move mr-2"
                >
                  {{ icons.mdiDrag }}
                </v-icon>
                <v-autocomplete
                  v-model="sort.field"
                  placeholder="Pilih Sortir"
                  outlined
                  dense
                  :items="fieldOptions.filter(option => !jobSort.filter((el, idx) => idx < index).map((sort) => sort.field.value).includes(option.value))"
                  item-text="label"
                  item-value="value"
                  hide-details="auto"
                  return-object
                  :rules="[required]"
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              md="5"
            >
              <v-btn-toggle
                v-model="sort.type"
                mandatory
                color="primary"
                class="d-flex flex-row"
              >
                <v-btn
                  v-for="option in sort.field.data_type_id ? orderOptions[sort.field.data_type_id] : orderOptions[sort.field.value]"
                  :key="option.label"
                  outlined
                  :value="option.value"
                  class="flex-grow-1"
                >
                  {{ option.label }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col
              v-if="index !== 0"
              cols="1"
              md="1"
              dense
              class="pr-0 pl-1 align-self-center"
            >
              <v-btn
                small
                icon
                plain
                @click="removeSort(index)"
              >
                <v-icon>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </draggable>
      </v-form>

      <div class="d-flex flex-column flex-sm-row mt-5 pt-5">
        <v-btn
          :disabled="!(jobSort && (jobSort.length < fieldOptions.length))"
          class="mb-2 mb-sm-0 mr-sm-2"
          outlined
          @click="addSort()"
        >
          Tambah Sortir
        </v-btn>
        <v-btn
          class="mb-2 mb-sm-0 mr-sm-2 ml-sm-auto"
          outlined
          @click="clearSort()"
        >
          Reset Sortir
        </v-btn>
        <v-btn
          color="primary"
          elevation="1"
          @click="sort"
        >
          Sortir Job
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiDrag } from '@mdi/js'
import { createFieldMapper } from 'vuex-use-fields'
import { required } from '@core/utils/validation'
import store from '@/store'
import draggable from 'vuedraggable'

const useFieldJob = createFieldMapper({ getter: 'job/getField', setter: 'job/setField' })

export default {
  components: {
    draggable,
  },
  setup(props, { emit }) {
    const state = { ...useFieldJob(['jobSort']) }
    const jobSort = ref(null)

    const dateOrder = [
      {
        label: 'Terbaru',
        value: 'DESC',
      },
      {
        label: 'Terlama',
        value: 'ASC',
      },
    ]

    const timeOrder = [
      {
        label: 'Terdekat',
        value: 'ASC',
      },
      {
        label: 'Terlama',
        value: 'DESC',
      },
    ]

    const orderOptions = {
      name: [
        {
          label: 'A - Z',
          value: 'ASC',
        },
        {
          label: 'Z - A',
          value: 'DESC',
        },
      ],
      start_date: timeOrder,
      expected_close_date: timeOrder,
      priority: [
        {
          label: 'Rendah - Tinggi',
          value: 'ASC',
        },
        {
          label: 'Tinggi - Rendah',
          value: 'DESC',
        },
      ],
      status: [
        {
          label: 'Rendah - Tinggi',
          value: 'ASC',
        },
        {
          label: 'Tinggi - Rendah',
          value: 'DESC',
        },
      ],
      created_at: dateOrder,
      updated_at: dateOrder,
      1: [
        {
          label: 'A - Z',
          value: 'ASC',
        },
        {
          label: 'Z - A',
          value: 'DESC',
        },
      ],
      2: [
        {
          label: '1 - 9',
          value: 'ASC',
        },
        {
          label: '9 - 1',
          value: 'DESC',
        },
      ],
      3: dateOrder,
    }

    const fieldOptions = ref(null)
    const staticFields = [
      {
        label: 'Nama Job',
        value: 'name',
      },
      {
        label: 'Tanggal Dimulai',
        value: 'start_date',
      },
      {
        label: 'Tanggal Ditutup',
        value: 'expected_close_date',
      },
      {
        label: 'Prioritas',
        value: 'priority',
      },
      {
        label: 'Status',
        value: 'status',
      },
      {
        label: 'Waktu Dibuat',
        value: 'created_at',
      },
      {
        label: 'Waktu Diupdate',
        value: 'updated_at',
      },
    ]

    const jobSortForm = ref()
    const isOpen = ref(false)

    const close = () => {
      isOpen.value = false
    }

    const clearSort = () => {
      store.dispatch('job/resetSort')

      jobSortForm.value.reset()
      jobSort.value = state.jobSort.value.map(el => ({ ...el }))
      emit('sort')
      close()
    }

    const removeSort = index => {
      jobSort.value.splice(index, 1)
    }

    const addSort = () => {
      jobSort.value.push({
        field: fieldOptions.value.filter(option => !jobSort.value.map(el => el.field.value).includes(option.value))[0],
        type: 'ASC',
      })
    }

    const show = customAttributesArray => {
      if (state.jobSort.value.length) jobSort.value = state.jobSort.value.map(el => ({ ...el }))
      else {
        jobSort.value = [
          {
            field: {
              label: null,
              value: null,
            },
            type: 'DESC',
          },
        ]
      }

      isOpen.value = true
      fieldOptions.value = [...staticFields, ...customAttributesArray.filter(el => ![4, 5].includes(el.custom_attribute.data_type.id)).map(ca => ({
        label: ca.custom_attribute.name,
        value: `custom_attribute_values->>'$."${ca.custom_attribute.id}"'`,
        data_type_id: ca.custom_attribute.data_type.id,
      }))]
    }

    const sort = () => {
      if (jobSortForm.value.validate()) {
        state.jobSort.value = jobSort.value

        emit('sort')
        close()
      }
    }

    return {
      props,
      jobSortForm,
      isOpen,
      jobSort,

      required,
      clearSort,
      sort,
      show,
      close,
      addSort,
      removeSort,
      fieldOptions,
      orderOptions,

      icons: {
        mdiClose,
        mdiDrag,
      },
    }
  },
}
</script>
