<template>
  <div>
    <div class="d-flex justify-space-between grid-toolbar subtitle-1">
      <div>
        <v-btn
          tile
          text
          class="ps-6 px-3 font-weight-regular"
          @click="handleToolbarClick('zoomIn')"
        >
          <v-icon left>
            {{ icons.mdiMagnifyPlus }}
          </v-icon>
          Zoom in
        </v-btn>
        <v-btn
          tile
          text
          class="px-3 font-weight-regular"
          @click="handleToolbarClick('zoomOut')"
        >
          <v-icon left>
            {{ icons.mdiMagnifyMinus }}
          </v-icon>
          Zoom out
        </v-btn>
        <v-btn
          tile
          text
          class="px-3 font-weight-regular"
          @click="handleToolbarClick('zoomFit')"
        >
          <v-icon left>
            {{ icons.mdiStretchToPageOutline }}
          </v-icon>
          Zoom to fit
        </v-btn>
      </div>
      <div>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          offset-y
          transition="slide-y-reverse-transition"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              tile
              text
              v-bind="attrs"
              class="px-3 font-weight-regular"
              v-on="on"
            >
              Columns
              <v-icon right>
                {{ icons.mdiChevronDown }}
              </v-icon>
            </v-btn>
          </template>
          <v-card width="250">
            <v-card-title>Pilih Kolom</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="search"
                label="Cari"
                placeholder="Nama Kolom"
                dense
                hide-details
              />
              <v-list v-if="instanceColumns">
                <v-list-item
                  v-for="column in instanceColumns.filter(col => col.headerText.includes(search))"
                  :key="column.field"
                  class="px-0"
                >
                  <v-switch
                    :label="column.headerText"
                    :input-value="column.visible"
                    inset
                    dense
                    hide-details
                    color="primary"
                    @change="handleColumnChooser(column.headerText, $event)"
                  />
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </div>
    <ejs-gantt
      id="gantt"
      ref="gantt"
      :data-source="jobs"
      :task-fields="fields"
      :columns="columns"
      :label-settings="labelSettings"
      :selection-settings="selectionSettings"
      :edit-settings="editSettings"
      :allow-reordering="true"
      :data-bound="dataBound"
      :allow-resizing="true"
      :allow-unscheduled-tasks="true"
      :include-weekend="true"
      :enable-context-menu="true"
      :context-menu-items="contextMenuItems"
      :action-begin="handleActionBegin"
      :splitter-settings="splitterSettings"
      :allow-row-drag-and-drop="true"
      height="690px"
      row-height="45"
    />
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import AssigneeColumn from '@/views/job/columns/AssigneeColumn.vue'
import CreatedByColumn from '@/views/job/columns/CreatedByColumn.vue'
import PriorityColumn from '@/views/job/columns/PriorityColumn.vue'
import StatusColumn from '@/views/job/columns/StatusColumn.vue'
import SelectMultipleColumn from '@/views/custom-attributes/SelectMultipleColumn.vue'
import {
  Edit,
  Selection,
  Reorder,
  Resize,
  ContextMenu,
  VirtualScroll,
  RowDD,
} from '@syncfusion/ej2-vue-gantt'
import {
  mdiMagnifyPlus,
  mdiMagnifyMinus,
  mdiStretchToPageOutline,
  mdiChevronDown,
} from '@mdi/js'

export default {
  props: {
    jobList: {
      type: Array,
      default: () => [],
    },
    customAttributesArray: {
      type: Array,
      default: () => [],
    },
  },
  provide: {
    gantt: [
      Edit,
      Selection,
      Reorder,
      Resize,
      ContextMenu,
      VirtualScroll,
      RowDD,
    ],
  },
  setup(props, { emit }) {
    const gantt = ref()
    const menu = ref()
    const search = ref('')
    const instanceColumns = ref()
    const customAttributesArray = computed(() => props.customAttributesArray)

    const statusColumn = () => ({ template: StatusColumn })
    const priorityColumn = () => ({ template: PriorityColumn })
    const createdByColumn = () => ({ template: CreatedByColumn })
    const assigneeColumn = () => ({ template: AssigneeColumn })
    const selectMultipleColumn = () => ({ template: SelectMultipleColumn })

    const jobs = computed(() => props.jobList.map(job => ({
      ...job,
      close_date: job.expected_close_date,
      tasks: job.tasks.map(task => ({
        ...task,
        name: task.subject,
      })),
    })))
    const fields = ref({
      id: 'id',
      name: 'name',
      startDate: 'start_date',
      endDate: 'close_date',
      child: 'tasks',
    })
    const editSettings = ref({
      allowAdding: true,
      allowEditing: true,
      allowDeleting: true,
      showDeleteConfirmDialog: true,
      allowTaskbarEditing: true,
    })
    const labelSettings = ref({
      leftLabel: 'name',
    })
    const splitterSettings = ref({
      columnIndex: 4,
    })
    const selectionSettings = ref({
      type: 'Multiple',
    })
    const contextMenuItems = ref([
      'AutoFitAll',
      'AutoFit',
      'TaskInformation',
      'DeleteTask',
      'Save',
      'Cancel',
      'SortAscending',
      'SortDescending',
      'Add',
      'Save',
      'Cancel',
    ])
    const columns = ref([
      {
        field: 'name',
        width: '200',
        headerText: 'Nama',
        headerTextAlign: 'Center',
      },
      {
        field: 'start_date',
        width: '120',
        headerText: 'Tanggal Mulai',
        headerTextAlign: 'Center',
      },
      {
        field: 'close_date',
        width: '120',
        headerText: 'Tanggal Akhir',
        headerTextAlign: 'Center',
      },
      {
        field: 'assignedTo',
        headerText: 'Ditugaskan ke',
        width: '220',
        textAlign: 'Left',
        allowEditing: false,
        template: assigneeColumn,
      },
      {
        field: 'priority',
        headerText: 'Prioritas',
        width: '200',
        textAlign: 'Left',
        visible: false,
        template: priorityColumn,
      },
      {
        field: 'status',
        headerText: 'Status',
        width: '200',
        textAlign: 'Left',
        visible: false,
        template: statusColumn,
      },
      {
        field: 'created_by',
        headerText: 'Dibuat Oleh',
        width: '200',
        textAlign: 'Left',
        visible: false,
        template: createdByColumn,
      },
      {
        field: 'created_at',
        headerText: 'Dibuat pada',
        width: '220',
        textAlign: 'Left',
        type: 'dateTime',
        visible: false,
        format: { type: 'dateTime', format: 'dd MMMM yyyy · hh:mm' },
      },
      {
        field: 'updated_at',
        headerText: 'Diupdate pada',
        width: '220',
        textAlign: 'Left',
        type: 'dateTime',
        visible: false,
        format: { type: 'dateTime', format: 'dd MMMM yyyy · hh:mm' },
      },
    ])

    const flag = ref(true)
    const dataBound = () => {
      if (flag.value === true) {
        flag.value = false

        // push custom attributes columns properties
        customAttributesArray.value.forEach(el => {
          if ([1, 2].includes(el.custom_attribute.data_type.id)) {
            columns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              dataTypeId: el.custom_attribute.data_type.id,
            })
          } else if (el.custom_attribute.data_type.id === 3) {
            columns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              type: 'dateTime',
              format: { type: 'dateTime', format: 'dd MMMM yyyy · hh:mm' },
              dataTypeId: el.custom_attribute.data_type.id,
            })
          } else if (el.custom_attribute.data_type.id === 4) {
            columns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              dataTypeId: el.custom_attribute.data_type.id,
            })
          } else if (el.custom_attribute.data_type.id === 5) {
            columns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              allowSorting: false,
              template: selectMultipleColumn,
              dataTypeId: el.custom_attribute.data_type.id,
            })
          }
        })

        instanceColumns.value = gantt.value.getGridColumns()
      }
    }

    const handleActionBegin = args => {
      console.log(args)
      /* eslint-disable no-param-reassign */
      if (args.requestType === 'beforeOpenEditDialog') {
        args.cancel = true
        if (args.rowData.level === 0) emit('jobEdit', args.rowData.id)
        else emit('taskEdit', args.rowData.taskData)
      }
      /* eslint-enable no-param-reassign */
    }

    const handleToolbarClick = action => {
      switch (action) {
        case 'zoomIn':
          gantt.value.zoomIn()
          break
        case 'zoomOut':
          gantt.value.zoomOut()
          break
        case 'zoomFit':
          gantt.value.fitToProject()
          break
        case 'columns':
          gantt.value.openColumnChooser()
          break
        default:
          break
      }
    }

    const handleColumnChooser = (name, event) => {
      if (event) gantt.value.showColumn(name)
      else gantt.value.hideColumn(name)
    }

    return {
      jobs,
      gantt,
      menu,
      instanceColumns,
      search,
      fields,
      editSettings,
      labelSettings,
      splitterSettings,
      contextMenuItems,
      columns,
      selectionSettings,
      dataBound,

      handleActionBegin,
      handleToolbarClick,
      handleColumnChooser,

      icons: {
        mdiMagnifyPlus,
        mdiMagnifyMinus,
        mdiStretchToPageOutline,
        mdiChevronDown,
      },
    }
  },
}
</script>
<style lang="scss">
@import '~@core/preset/syncfusion/material/individual-scss/gantt/gantt.scss';
@import '~@/styles/syncfusion-overrides.scss';
</style>
