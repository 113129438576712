import gql from 'graphql-tag'

const jobUpdateSubscription = gql`
  subscription (
    $job_type_id: Float
    $job_id: Float
  ) {
    jobUpdateSubscription (
      job_type_id: $job_type_id
      job_id: $job_id
    ) {
      id
      name
      description
      strip_description
      actionType
      job_type {
        id
        name
      }
      status {
        id
        name
        color
        urutan
      }
      priority {
        id
        name
        color
      }
      start_date
      expected_close_date
      customer {
        id
        name
      }
      created_by {
        id
        first_name
        last_name
        name
      }
      created_at
      updated_at
      deleted_at
      assignedTo {
        id
        user {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
      }
      lexorank
      custom_attribute_values
    }
  }
`

export default jobUpdateSubscription
