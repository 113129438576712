<template>
  <div>
    <div class="d-flex job-kanban">
      <div
        v-for="(column, index) in jobKanban"
        :key="column.id"
      >
        <v-hover #default="{ hover: isHovered }">
          <kanban-board
            :column="column"
            :index="index"
            :check-move="handleMoveCard"
            :sortable="$can('update', 'JobStatus')"
            @sortColumn="handleColumnUpdate"
            @moveGroup="switchGroup"
            @moveRank="handleLexorank"
            @fetchMore="fetchMore++; renderList()"
          >
            <template #column-header="{ data }">
              <div
                class="kanban-header d-flex justify-space-between"
                :data-column="data.columnData"
              >
                <small class="mb-2">{{ data.columnData.name }}</small>
                <div class="d-flex">
                  <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    max-width="290px"
                    min-width="290px"
                    offset-y
                    left
                    transition="slide-x-transition"
                  >
                    <template v-slot:activator="{ on, attrs, value }">
                      <v-slide-x-reverse-transition mode="out-in">
                        <v-btn
                          v-if="groupBy.field === 'status'"
                          v-show="isHovered || value"
                          icon
                          x-small
                          class="mr-1"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon
                            :key="1"
                            size="24px"
                          >
                            {{ icons.mdiDotsHorizontal }}
                          </v-icon>
                        </v-btn>
                      </v-slide-x-reverse-transition>
                      <v-slide-x-reverse-transition mode="out-in">
                        <v-btn
                          v-show="isHovered || value"
                          icon
                          x-small
                          @click="$emit('add', { id: data.columnData.id, field: groupBy.field })"
                        >
                          <v-icon
                            :key="1"
                            size="24px"
                          >
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </v-btn>
                      </v-slide-x-reverse-transition>
                    </template>
                    <v-card>
                      <v-list>
                        <div class="px-4 mt-2 d-flex justify-space-between">
                          <span class="text-subtitle-2 font-medium">Ubah Status</span>
                          <v-icon
                            size="20"
                            @click="resetColumn(data.index)"
                          >
                            {{ icons.mdiClose }}
                          </v-icon>
                        </div>
                        <div class="px-4 mt-2">
                          <v-text-field
                            v-model="data.columnData.name"
                            :placeholder="`Nama Status`"
                            hide-details
                            dense
                            @keydown="$event.keyCode === 13 ? updateStatusDetail(data.columnData, data.index) : null"
                          />
                        </div>
                        <div class="px-4 mt-6">
                          <color-picker
                            v-model="data.columnData.color"
                            :label="`Warna Status`"
                            :attach="false"
                          />
                        </div>
                        <div
                          v-if="groupBy.field === 'status'"
                          class="px-4 mt-4"
                        >
                          <div class="d-flex justify-space-between align-center">
                            <small>Status Selanjutnya</small>
                            <v-icon
                              color="primary"
                              size="18"
                            >
                              {{ icons.mdiHelpCircleOutline }}
                            </v-icon>
                          </div>
                          <v-select
                            v-model="data.columnData.next_job_status_id"
                            :items="data.index + 1 === jobKanban.length ? columnGroup : columnGroup.filter(el => el.id !== data.columnData.id)"
                            multiple
                            chips
                            small-chips
                            hide-details
                            dense
                            item-text="name"
                            item-value="id"
                          >
                            <template #selection="{ item }">
                              <v-chip
                                :color="item.color"
                                small
                                class="mb-1"
                              >
                                {{ item.name }}
                              </v-chip>
                            </template>
                          </v-select>
                        </div>
                      </v-list>
                      <v-divider />
                      <div class="d-flex">
                        <v-btn
                          color="error"
                          text
                          @click="deleteStatus(data.columnData.id, data.index)"
                        >
                          Hapus
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          text
                          @click="updateStatusDetail(data.columnData, data.index)"
                        >
                          Simpan
                        </v-btn>
                      </div>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </template>
            <template #kanban-card="{ data }">
              <job-card
                :key="data.id"
                :data="data"
                is-kanban
                @showtask="$emit('showtask', {job: $event, customer: $event.customer})"
                @delete="$emit('delete', $event)"
                @update="$emit('update', $event)"
                @refetch="renderList()"
              />
            </template>
          </kanban-board>
        </v-hover>
      </div>
    </div>

    <attribute-delete-confirmation
      ref="attributeDeleteConfirmation"
      @update="handleUpdateConfirmation()"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ref, onMounted, computed,
} from '@vue/composition-api'
import {
  mdiDotsHorizontal, mdiClose, mdiHelpCircleOutline, mdiPlus,
} from '@mdi/js'
import { useDebounceFn } from '@vueuse/core'
import { apolloClient } from '@/vue-apollo'
import { jobPriority, jobStatus, customAttributeOptions } from '@/graphql/queries'
import {
  deleteJobStatus,
  proceedJobStatus,
  proceedJobPriority,
  updateJobStatus,
  updateJobStatusUrutan,
  updateCustomAttributeOptionValue,
} from '@/graphql/mutations'
import KanbanBoard from '@/components/misc/KanbanBoard.vue'
import ColorPicker from '@/components/inputs/ColorPicker.vue'
import JobCard from './JobCard.vue'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'
import AttributeDeleteConfirmation from './AttributeDeleteConfirmation.vue'
import { createFieldMapper } from 'vuex-use-fields'
import { LexoRank } from 'lexorank'
import useLexorank from '@/composables/useLexorank'

const useFieldJob = createFieldMapper({ getter: 'job/getField', setter: 'job/setField' })

export default {
  components: {
    KanbanBoard,
    ColorPicker,
    JobCard,
    AttributeDeleteConfirmation,
  },
  props: {
    types: {
      type: Object,
      default: () => {},
    },
    jobs: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: null,
      required: true,
    },
    serverItemsLength: {
      type: Number,
      default: null,
      required: true,
    },
  },
  setup(props) {
    const menu = ref()
    const attributeDeleteConfirmation = ref()

    const { updateObjectLexorank } = useLexorank({ objectType: 'job' })
    const state = { ...useFieldJob(['jobFilter', 'jobSort']) }
    const jobKanban = ref([])
    const columnGroup = ref([])
    const loadingFetch = ref(true)

    const jobs = computed(() => props.jobs)
    const groupBy = computed(() => state.jobFilter.value.group)
    const groupOrder = computed(() => state.jobFilter.value.groupOrder)

    const fetchMore = ref(0)

    const pagination = computed(() => {
      const pageCount = props.serverItemsLength / props.itemsPerPage
      const pageStart = props.options.page === 1 ? 0 : (props.options.page - 1) * props.itemsPerPage

      return {
        itemsLength: props.serverItemsLength,
        itemsPerPage: props.itemsPerPage,
        page: props.options.page,
        pageCount,
        pageStart,
        pageStop: pageStart + props.itemsPerPage + (fetchMore.value * props.itemsPerPage),
      }
    })

    const renderList = () => {
      if (groupBy.value.field === 'status') {
        jobKanban.value = columnGroup.value.map(el => ({
          id: el.id,
          name: el.name,
          color: el.color,
          type: el.type,
          lists: jobs.value.filter(job => job.status.id === el.id).slice(pagination.value.pageStart, pagination.value.pageStop),
          next_job_status_id: el.job_order?.length
            ? el.job_order.map(order => order.next_job_status_id)
            : [],
        }))
      } else if (groupBy.value.field === 'priority') {
        jobKanban.value = columnGroup.value.reduce((acc, el) => {
          acc.push({
            id: el.id,
            name: el.name,
            color: el.color,
            lists: jobs.value.filter(job => job.priority?.id === el.id).slice(pagination.value.pageStart, pagination.value.pageStop),
          })

          return acc
        }, [])

        jobKanban.value.push({
          id: null,
          name: 'Unset',
          color: '#8a8d93',
          lists: jobs.value.filter(job => !job.priority).slice(pagination.value.pageStart, pagination.value.pageStop),
        })
      } else {
        jobKanban.value = columnGroup.value.reduce((acc, el) => {
          acc.push({
            id: el.id,
            name: el.name,
            color: '#6285f6',
            lists: jobs.value.filter(job => job.custom_attribute_values[Number(groupBy.value.field)] === el.name).slice(pagination.value.pageStart, pagination.value.pageStop),
          })

          return acc
        }, [])

        jobKanban.value.push({
          id: null,
          name: 'Unset',
          color: '#8a8d93',
          lists: jobs.value.filter(job => !job.custom_attribute_values[Number(groupBy.value.field)]).slice(pagination.value.pageStart, pagination.value.pageStop),
        })
      }
    }

    const fetchJobStatus = id => {
      loadingFetch.value = true
      apolloClient.query({
        query: jobStatus,
        variables: {
          job_type: [id],
          order: groupOrder.value,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        columnGroup.value = result.data.jobStatus
        renderList()
        loadingFetch.value = false
      }).catch(err => {
        loadingFetch.value = false
        errorHandling(err)
      })
    }

    const fetchJobPriority = id => {
      loadingFetch.value = true
      apolloClient.query({
        query: jobPriority,
        fetchPolicy: 'no-cache',
        variables: {
          job_type: [id],
          order: groupOrder.value,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        columnGroup.value = result.data.jobPriority
        renderList()
        loadingFetch.value = false
      }).catch(err => {
        loadingFetch.value = false
        errorHandling(err)
      })
    }

    const fetchCustomAttributeOptions = id => {
      loadingFetch.value = true
      apolloClient.query({
        query: customAttributeOptions,
        fetchPolicy: 'no-cache',
        variables: {
          custom_attribute_id: id,
          order: groupOrder.value,
        },
      }).then(result => {
        columnGroup.value = result.data.customAttributeOptions
        renderList()
        loadingFetch.value = false
      }).catch(error => {
        loadingFetch.value = false
        errorHandling(error)
      })
    }

    const getLexorank = data => {
      const dataAbove = jobKanban.value[data.index].lists[data.dragData.newIndex - 1]
      const dataBelow = jobKanban.value[data.index].lists[data.dragData.newIndex + 1]
      const movedData = data.dragData.element
      let movedDataLexorank = LexoRank.parse(movedData.lexorank)
      if (dataAbove && dataBelow) {
        const dataAboveLexorank = LexoRank.parse(dataAbove.lexorank)
        const dataBelowLexorank = LexoRank.parse(dataBelow.lexorank)
        movedDataLexorank = dataAboveLexorank.between(dataBelowLexorank)
      } else if (dataAbove) {
        const dataAboveLexorank = LexoRank.parse(dataAbove.lexorank)
        movedDataLexorank = dataAboveLexorank.genNext()
      } else if (dataBelow) {
        const dataBelowLexorank = LexoRank.parse(dataBelow.lexorank)
        movedDataLexorank = dataBelowLexorank.genPrev()
      }

      return movedDataLexorank
    }

    const invalidMoveRank = useDebounceFn(() => {
      Vue.notify({
        title: 'Tidak Valid',
        text: 'Hanya boleh mengubah urutan pada mode sortir RANK',
        type: 'warn',
      })
    }, 200)

    const handleLexorank = data => {
      if (state.jobSort.value.length !== 0) {
        invalidMoveRank()
        renderList()
      } else {
        updateObjectLexorank(getLexorank(data).toString(), data.dragData.element.id, store.getters.getCurrentWorkspaceId)
      }
    }

    const handleColumnUpdate = data => {
      apolloClient.mutate({
        mutation: updateJobStatusUrutan,
        variables: {
          params: data.rawData.map((el, index) => ({
            id: el.id,
            urutan: index + 1,
          })),
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      })
    }

    const updateStatusDetail = (data, index) => {
      apolloClient.mutate({
        mutation: updateJobStatus,
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          job_status: {
            id: data.id,
            name: data.name,
            color: data.color,
            type: data.type,
            next_job_status_id: data.next_job_status_id,
          },
        },
      }).then(() => {
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah status!',
        })
        fetchJobStatus(props.types.id)
        menu.value[index].save()
      }).catch(err => {
        errorHandling(err)
      })
    }

    const switchGroup = data => {
      /* TODO : handle refetch */
      const lexorank = getLexorank(data).toString()
      if (groupBy.value.field === 'status') {
        apolloClient.mutate({
          mutation: proceedJobStatus,
          variables: {
            lexorank,
            id: data.dragData.element.id,
            status_id: jobKanban.value[data.index].id,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah status job!',
          })
        }).catch(err => {
          errorHandling(err)
          renderList()
        })
      } else if (groupBy.value.field === 'priority') {
        apolloClient.mutate({
          mutation: proceedJobPriority,
          variables: {
            lexorank,
            id: data.dragData.element.id,
            priority_id: jobKanban.value[data.index].id,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah prioritas job!',
          })
        }).catch(err => {
          errorHandling(err)
          renderList()
        })
      } else {
        apolloClient.mutate({
          mutation: updateCustomAttributeOptionValue,
          variables: {
            custom_attribute_id: groupBy.value.field,
            object_id: data.dragData.element.id,
            option_id: jobKanban.value[data.index].id,
            type_id: 2,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah atribut job!',
          })
        }).catch(err => {
          errorHandling(err)
          renderList()
        })
      }
    }

    const deleteStatus = id => {
      try {
        if (id === jobKanban.value[0].id || id === jobKanban.value[jobKanban.value.length - 1].id) {
          throw new Error('Tidak dapat menghapus status awal atau akhir')
        }
        Vue.$dialog({
          title: 'Hapus status?',
          body: 'Yakin ingin hapus status ini?',
        }).then(confirm => {
          if (confirm) {
            apolloClient.mutate({
              mutation: deleteJobStatus,
              variables: {
                delete_id: id,
                workspace_id: store.getters.getCurrentWorkspaceId,
              },
            }).then(data => {
              if (data.data.deleteJobStatus.status === 'warning') {
                attributeDeleteConfirmation.value.show(JSON.parse(data.data.deleteJobStatus.data).job, jobKanban.value.filter(el => el.id !== id))
                throw new Error(data.data.deleteJobStatus.msg)
              }
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil menghapus status!',
              })
              fetchJobStatus(props.types.id)
            }).catch(err => {
              Vue.notify({
                title: 'Warning',
                text: err.message,
                type: 'warn',
              })
            })
          }
        })
      } catch (error) {
        errorHandling(error)
      }
    }

    const invalidMove = useDebounceFn((fromStatus, toStatus) => {
      Vue.notify({
        title: 'Tidak Valid',
        text: `Tidak diperbolehkan merubah status job dari ${fromStatus} ke ${toStatus}`,
        type: 'warn',
      })
    }, 200)

    const handleMoveCard = data => {
      const fromId = +data.draggedContext.element.status.id
      const toId = +data.relatedContext.element.status.id

      const fromObject = jobKanban.value.find(el => el.id === fromId)

      if (fromObject && fromObject.next_job_status_id && fromObject.next_job_status_id.length && fromId !== toId) {
        if (!fromObject.next_job_status_id.some(el => el === toId)) {
          invalidMove(fromObject.name, jobKanban.value.find(el => el.id === toId).name)

          return false
        }
      }

      return true
    }

    const fetchGroupColumns = () => {
      if (groupBy.value.field === 'status') fetchJobStatus(props.types.id)
      else if (groupBy.value.field === 'priority') fetchJobPriority(props.types.id)
      else columnGroup.value = fetchCustomAttributeOptions(groupBy.value.field)
    }

    const handleUpdateConfirmation = () => {
      renderList()
      fetchGroupColumns()
    }

    const resetColumn = index => {
      renderList()
      menu.value[index].save()
    }

    onMounted(() => {
      fetchGroupColumns()
    })

    return {
      menu,
      attributeDeleteConfirmation,

      jobKanban,
      columnGroup,
      loadingFetch,
      pagination,
      groupBy,
      groupOrder,
      fetchMore,

      fetchJobStatus,
      fetchGroupColumns,
      handleColumnUpdate,
      updateStatusDetail,
      resetColumn,
      switchGroup,
      handleUpdateConfirmation,
      handleMoveCard,
      deleteStatus,
      renderList,
      handleLexorank,

      icons: {
        mdiDotsHorizontal,
        mdiClose,
        mdiPlus,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';

  .job-kanban {
    @include style-scroll-bar();
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      background: none;
      padding-top: 16px;
    }
  }
  .kanban-footer {
    position: fixed;
    bottom: 16px;
    right: 0;
    margin: 0 24px;
    z-index: 10;

    .kanban-pagination {
      .v-data-footer__select {
        margin-left: 0 !important;
      }

      .v-data-footer__pagination {
        margin-left: auto !important;
      }
    }
  }
</style>
