<template>
  <div v-if="data">
    <v-chip
      v-for="option in data.custom_attribute_values[data.column.field.split('.', 2)[1]]"
      :key="option"
      class="mr-1 my-1"
      small
    >
      {{ option }}
    </v-chip>
  </div>
  <span v-else />
</template>
<script>
import { reactive } from '@vue/composition-api'

export default {
  setup() {
    const data = reactive({})

    return {
      data,
    }
  },
}
</script>
