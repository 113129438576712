import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between grid-toolbar subtitle-1"},[_c('div',[_c(VBtn,{staticClass:"ps-6 px-3 font-weight-regular",attrs:{"tile":"","text":""},on:{"click":function($event){return _vm.handleToolbarClick('zoomIn')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiMagnifyPlus)+" ")]),_vm._v(" Zoom in ")],1),_c(VBtn,{staticClass:"px-3 font-weight-regular",attrs:{"tile":"","text":""},on:{"click":function($event){return _vm.handleToolbarClick('zoomOut')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiMagnifyMinus)+" ")]),_vm._v(" Zoom out ")],1),_c(VBtn,{staticClass:"px-3 font-weight-regular",attrs:{"tile":"","text":""},on:{"click":function($event){return _vm.handleToolbarClick('zoomFit')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiStretchToPageOutline)+" ")]),_vm._v(" Zoom to fit ")],1)],1),_c('div',[_c(VMenu,{attrs:{"close-on-content-click":false,"offset-y":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"px-3 font-weight-regular",attrs:{"tile":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" Columns "),_c(VIcon,{attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronDown)+" ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,{attrs:{"width":"250"}},[_c(VCardTitle,[_vm._v("Pilih Kolom")]),_c(VCardText,[_c(VTextField,{attrs:{"label":"Cari","placeholder":"Nama Kolom","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.instanceColumns)?_c(VList,_vm._l((_vm.instanceColumns.filter(function (col) { return col.headerText.includes(_vm.search); })),function(column){return _c(VListItem,{key:column.field,staticClass:"px-0"},[_c(VSwitch,{attrs:{"label":column.headerText,"input-value":column.visible,"inset":"","dense":"","hide-details":"","color":"primary"},on:{"change":function($event){return _vm.handleColumnChooser(column.headerText, $event)}}})],1)}),1):_vm._e()],1)],1)],1)],1)]),_c('ejs-gantt',{ref:"gantt",attrs:{"id":"gantt","data-source":_vm.jobs,"task-fields":_vm.fields,"columns":_vm.columns,"label-settings":_vm.labelSettings,"selection-settings":_vm.selectionSettings,"edit-settings":_vm.editSettings,"allow-reordering":true,"data-bound":_vm.dataBound,"allow-resizing":true,"allow-unscheduled-tasks":true,"include-weekend":true,"enable-context-menu":true,"context-menu-items":_vm.contextMenuItems,"action-begin":_vm.handleActionBegin,"splitter-settings":_vm.splitterSettings,"allow-row-drag-and-drop":true,"height":"690px","row-height":"45"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }