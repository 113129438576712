<template>
  <v-hover #default="{ hover }">
    <v-card
      class="d-flex flex-column flex-grow-1 pa-4 mb-2"
      outlined
      @click="$router.push({ name: 'job-detail', params: { id: data.id } })"
    >
      <div class="d-flex align-start">
        <span class="text-subtitle-2 font-weight-bold d-block primary--text mb-2">{{ data.name }}</span>
        <v-menu
          v-if="!isArchive"
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-slide-x-reverse-transition mode="out-in">
              <v-icon
                v-show="hover"
                size="20px"
                v-bind="attrs"
                class="ml-auto mt-2"
                v-on="on"
              >
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-slide-x-reverse-transition>
          </template>
          <v-list>
            <v-list-item
              @click="$emit('showtask', data)"
            >
              Buat Task
            </v-list-item>
            <v-divider class="my-1" />
            <v-list-item
              @click="$router.push({ name: 'job-detail', params: { id: data.id } })"
            >
              Lihat Detail
            </v-list-item>
            <v-list-item
              @click="$emit('update', data.id)"
            >
              Ubah Data
            </v-list-item>
            <v-list-item
              v-if="$can('del', 'Job')"
              class="error--text"
              @click="$emit('delete', data.id)"
            >
              Hapus Job
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-else
          rounded
          outlined
          small
          text
          class="ml-auto"
          @click.stop="$emit('restore', data.id)"
        >
          Pulihkan
        </v-btn>
      </div>
      <div class="d-flex flex-wrap">
        <v-chip
          v-if="!isKanban"
          class="mr-1 mb-1"
          :color="data.status.color"
          small
        >
          {{ data.status.name }}
        </v-chip>
        <v-chip
          v-if="!isKanban"
          class="mb-1"
          color="primary"
          small
        >
          {{ data.job_type.name }}
        </v-chip>
      </div>
      <span class="text-caption font-medium mb-2">{{ ellipsis(data.strip_description, 76) }}</span>
      <div
        v-if="data.customer"
        class="d-flex flex-column"
      >
        <span class="text-caption text--disabled">Pelanggan</span>
        <router-link :to="{ name: 'customer-detail', params: { id: data.customer.id } }">
          <span class="text-caption font-medium primary--text">{{ data.customer.name }}</span>
        </router-link>
      </div>
      <div class="d-flex justify-space-between align-end">
        <div class="d-flex flex-column mt-2">
          <span class="text-caption text--disabled">Estimasi Waktu Ditutup</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span
                class="text-subtitle-2"
                v-bind="attrs"
                v-on="on"
              >
                <span class="text-caption font-medium">{{ data.expected_close_date ? dateFormat(data.expected_close_date, 3) : '-' }}</span>
              </span>
            </template>
            <div class="d-flex flex-column">
              <span>Dibuat: {{ dateFormat(data.created_at, 3) }}</span>
              <span>Diperbarui: {{ dateFormat(data.updated_at, 3) }}</span>
            </div>
          </v-tooltip>
        </div>
        <v-tooltip
          v-if="false"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-if="data.priority"
              small
              :color="data.priority.color"
              v-bind="attrs"
              v-on="on"
            >
              {{ data.priority.name }}
            </v-chip>
          </template>
          <span>Prioritas Job</span>
        </v-tooltip>
      </div>
      <div
        v-if="false"
        class="mt-2"
      >
        <span class="text-caption text--disabled mb-1 d-block">Ditugaskan ke</span>
        <div class="d-flex">
          <limit-elements
            v-if="data.assignedTo && data.assignedTo.length"
            :elements="data.assignedTo"
            :limit="3"
            class="v-avatar-group mr-auto"
            :class="rootThemeClasses"
          >
            <template #default="el">
              <v-tooltip
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-bind="attrs"
                    size="36"
                    class="primary"
                    v-on="on"
                  >
                    <v-img
                      v-if="el.data.user.photo"
                      :src="el.data.user.photo"
                    />
                    <span
                      v-else
                      class="white--text"
                    >
                      {{ avatarText(`${el.data.user.first_name} ${el.data.user.last_name}`) }}
                    </span>
                  </v-avatar>
                </template>
                <span>{{ el.data.user.first_name }} {{ el.data.user.last_name }}</span>
              </v-tooltip>
            </template>
            <template #others="el">
              <v-tooltip
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-bind="attrs"
                    size="36"
                    class="primary mb-1"
                    v-on="on"
                  >
                    <v-img
                      v-if="el.data.user.photo"
                      :src="el.data.user.photo"
                    />
                    <span
                      v-else
                      class="white--text"
                    >
                      {{ avatarText(`${el.data.user.first_name} ${el.data.user.last_name}`) }}
                    </span>
                  </v-avatar>
                </template>
                <span>{{ el.data.user.first_name }} {{ el.data.user.last_name }}</span>
              </v-tooltip>
            </template>
            <template #others-activator="el">
              <v-tooltip
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-bind="attrs"
                    size="36"
                    color="white"
                    class="primary--text text-caption"
                    v-on="on"
                  >
                    <span>+{{ el.data.limit }}</span>
                  </v-avatar>
                </template>
                <span>Lihat User Lain</span>
              </v-tooltip>
            </template>
          </limit-elements>
          <v-menu
            v-model="showMenu"
            :close-on-content-click="false"
            left
          >
            <template v-slot:activator="menu">
              <v-tooltip
                top
              >
                <template v-slot:activator="tooltip">
                  <v-btn
                    v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                    text
                    outlined
                    small
                    rounded
                    color="primary"
                    v-on="{ ...menu.on, ...tooltip.on }"
                    @click.stop
                  >
                    <v-icon
                      size="18"
                    >
                      {{ icons.mdiAccountPlus }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Tugaskan ke Pengguna</span>
              </v-tooltip>
            </template>

            <assign-object
              ref="assignObject"
              :job-id="data.id"
              :data="data.assignedTo"
              @success="$emit('refetch'); showMenu = false"
              @failed="$refs.assignObject.initForm()"
            />
          </v-menu>
        </div>
      </div>
      <div class="mt-2">
        <span class="text-caption text--disabled d-block">Dibuat oleh</span>
        <router-link :to="{ name: 'user-detail', params: { id: data.created_by.id } }">
          <span class="text-caption font-medium primary--text">{{ data.created_by.first_name }} {{ data.created_by.last_name }}</span>
        </router-link>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiDotsVertical, mdiAccountPlus,
} from '@mdi/js'
import { ellipsis, avatarText } from '@core/utils/filter'
import useVuetify from '@core/utils/vuetify'
import LimitElements from '@/components/misc/LimitElements.vue'
import dateFormat from '@/utils/dateFormat'
import AssignObject from '@/components/inputs/AssignObject.vue'

export default {
  components: {
    LimitElements,
    AssignObject,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    isKanban: {
      type: Boolean,
      default: false,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { rootThemeClasses } = useVuetify()
    const showMenu = ref(false)

    return {
      ellipsis,
      dateFormat,
      rootThemeClasses,
      avatarText,
      showMenu,

      icons: {
        mdiDotsVertical,
        mdiAccountPlus,
      },
    }
  },
}
</script>

<style>

</style>
