<template>
  <div>
    <template v-if="!loadingInitial">
      <XyzTransitionGroup
        appear
        xyz="fade down-4 stagger-2 out-stagger-2"
      >
        <v-card :key="1">
          <v-row
            class="mx-2 pt-2 align-center"
          >
            <v-col>
              <!-- Job Type Tabs -->
              <v-tabs
                v-model="activeTab"
                class="px-0"
                show-arrows
                style="box-shadow: none !important;"
              >
                <v-tab
                  v-for="(type) in jobTypeList"
                  :key="type.id"
                  class="text-capitalize overflow-hidden"
                  style="width: 220px"
                  @click="state.jobFilter.value.types = [type.id]; switchJobType(type.id)"
                >
                  <v-hover
                    #default="{ hover: isHovered }"
                  >
                    <v-row>
                      <v-col
                        cols="10"
                        class="pe-0"
                      >
                        <div
                          class="d-flex justify-space-between align-center"
                        >
                          <v-badge
                            dot
                            inline
                            bottom
                            overlap
                            class="mt-1"
                            :color="type.color"
                          />
                          <span
                            class="text-truncate mx-4"
                            style="max-width: 150px"
                          >
                            {{ type.name }}
                          </span>
                          <v-icon
                            v-if="!type.is_public"
                            size="14"
                            class="me-1"
                          >
                            {{ icons.mdiLockOutline }}
                          </v-icon>
                          <v-icon
                            v-if="type.archived_at"
                            size="14"
                          >
                            {{ icons.mdiArchive }}
                          </v-icon>
                          <span />
                        </div>
                      </v-col>
                      <v-col
                        cols="2"
                        class="pb-1"
                      >
                        <v-menu
                          v-if="folderPermissionGuard(type, 1)"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-slide-x-reverse-transition mode="out-in">
                              <v-icon
                                v-show="isHovered"
                                v-bind="attrs"
                                size="18"
                                v-on="on"
                              >
                                {{ icons.mdiDotsVertical }}
                              </v-icon>
                            </v-slide-x-reverse-transition>
                          </template>
                          <v-list>
                            <v-list-item
                              @click="$refs.typeForm.update(type)"
                            >
                              <v-list-item-title>{{ $can('del', 'Product') ? 'Edit' : 'Lihat Detail' }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              v-if="$can('del', 'Product')"
                              @click="$refs.editFolderPermissionForm.show(type)"
                            >
                              <v-list-item-title>Sharing & Permission</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              v-if="$can('del', 'Product')"
                              class="warning--text"
                              :disabled="!folderPermissionGuard(type, 0)"
                              @click="!type.archived_at ? confirmArchiveJobType(type) : confirmUnarchiveJobType(type)"
                            >
                              <v-list-item-title>{{ !type.archived_at ? 'Archive' : 'Unarchive' }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              v-if="$can('del', 'Product')"
                              class="error--text"
                              :disabled="!folderPermissionGuard(type, 0)"
                              @click="confirmDeleteJobType(type)"
                            >
                              <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-hover>
                </v-tab>
                <v-tooltip
                  v-if="$can('del', 'Product')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      small
                      class="align-self-center ms-4"
                      :style="{'border-top-right-radius':'0%', 'border-bottom-right-radius':'0%'}"
                      v-bind="attrs"
                      v-on="on"
                      @click="$refs.typeForm.add(id)"
                    >
                      <v-icon
                        class="light--text"
                        size="20"
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Buat Job Type Baru</span>
                </v-tooltip>
                <v-menu
                  v-if="$can('del', 'Product')"
                  v-model="isJobTypeFilterOpen"
                  :close-on-content-click="false"
                  offset-y
                  nudge-bottom="8px"
                  nudge-left="20px"
                  transition="slide-y-reverse-transition"
                >
                  <template v-slot:activator="menu">
                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="tooltip">
                        <v-badge
                          :value="jobTypeFilterCount > 0"
                          color="primary"
                          :content="jobTypeFilterCount"
                          overlap
                          class="align-self-center me-4"
                        >
                          <v-btn
                            color="primary"
                            small
                            outlined
                            :style="{'border-top-left-radius':'0%', 'border-bottom-left-radius':'0%'}"
                            v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                            v-on="{ ...menu.on, ...tooltip.on }"
                          >
                            <v-icon
                              class="light--text"
                              size="20"
                            >
                              {{ icons.mdiMenuDown }}
                            </v-icon>
                          </v-btn>
                        </v-badge>
                      </template>
                      <span>Search & Filter</span>
                    </v-tooltip>
                  </template>
                  <JobTypeFilter @onSearchJobType="getJobTypeData()" />
                </v-menu>
              </v-tabs>
            </v-col>

            <v-col
              cols="auto"
              class="ms-auto d-flex align-center"
            >
              <!-- Search Field Selector -->
              <v-menu
                :close-on-content-click="false"
                offset-y
                nudge-bottom="8px"
                nudge-left="20px"
                transition="slide-y-reverse-transition"
              >
                <template v-slot:activator="menu">
                  <v-tooltip bottom>
                    <template v-slot:activator="tooltip">
                      <v-icon
                        size="26"
                        v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                        v-on="{ ...menu.on, ...tooltip.on }"
                        @click.stop
                      >
                        {{ icons.mdiChevronDown }}
                      </v-icon>
                    </template>
                    <span>Cari job berdasarkan...</span>
                  </v-tooltip>
                </template>
                <v-card>
                  <v-card-text>
                    Cari Berdasarkan
                    <div
                      v-for="(field, index) in state.jobFilter.value.search_field"
                      :key="index"
                    >
                      <v-switch
                        v-model="field.value"
                        inset
                        flat
                        dense
                        hide-details
                        :label="field.label"
                        @change="fetchJobs()"
                      />
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>

              <!-- Search Field -->
              <input-icon-toggle
                v-model="state.jobFilter.value.search"
                class="ms-2 me-3"
                :icon="icons.mdiMagnify"
                placeholder="Cari job"
                @close="state.jobFilter.value.search = ''; fetchJobs()"
                @input="searchJob"
              />

              <!-- Refresh Button -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="26"
                    v-bind="attrs"
                    v-on="on"
                    @click="fetchJobs()"
                  >
                    {{ icons.mdiRefresh }}
                  </v-icon>
                </template>
                <span>Muat Ulang</span>
              </v-tooltip>

              <!-- Create Job -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ms-auto ms-md-4"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="$refs.jobForm.show(jobTypeDetail.id)"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>Buat Job</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row
            class="mx-2 mb-2"
          >
            <!-- Sort Button -->
            <v-col
              cols="12"
              lg="2"
              md="auto"
            >
              <v-badge
                :value="sortCount > 0"
                bordered
                overlap
                class="w-100 cursor-pointer"
                @click.native="clearSort"
              >
                <template
                  #badge
                >
                  <v-icon>
                    {{ icons.mdiClose }}
                  </v-icon>
                </template>
                <v-btn
                  outlined
                  block
                  large
                  :text="!sortCount"
                  :color="sortCount > 0 ? 'primary' : null"
                  @click.stop="$refs.jobSort.show(customAttributesArray)"
                >
                  Sortir Job {{ sortCount > 0 ? `(${sortCount})` : null }}
                </v-btn>
              </v-badge>
            </v-col>

            <!-- Group Button -->
            <v-col
              v-show="[0, 1].includes(viewTab)"
              cols="12"
              lg="2"
              md="auto"
            >
              <v-badge
                :value="viewTab !== 0 && state.jobFilter.value.group !== undefined"
                bordered
                overlap
                class="w-100 cursor-pointer"
                @click.native="state.jobFilter.value.group = undefined; groupGrid()"
              >
                <template
                  #badge
                >
                  <v-icon>
                    {{ icons.mdiClose }}
                  </v-icon>
                </template>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      block
                      large
                      v-bind="attrs"
                      :text="!state.jobFilter.value.group"
                      :color="state.jobFilter.value.group ? 'primary' : null"
                      v-on="on"
                    >
                      Grup Job
                    </v-btn>
                  </template>

                  <v-list
                    bottom
                    dense
                  >
                    <v-subheader>GRUP BERDASARKAN</v-subheader>
                    <v-list-item-group
                      v-model="state.jobFilter.value.group"
                      :mandatory="viewTab === 0"
                      color="primary"
                      @change="viewTab === 0 ? $refs.jobKanbanView.fetchGroupColumns() : groupGrid()"
                    >
                      <v-list-item
                        v-for="option in groupOptions"
                        :key="option.field"
                        :value="option"
                      >
                        {{ option.label }}
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>

                  <v-divider />

                  <v-list
                    bottom
                    nav
                    dense
                  >
                    <v-subheader>URUTKAN</v-subheader>
                    <v-list-item-group
                      v-model="state.jobFilter.value.groupOrder"
                      mandatory
                      color="primary"
                      @change="viewTab === 0 ? $refs.jobKanbanView.fetchGroupColumns() : groupGrid()"
                    >
                      <v-list-item value="ASC">
                        <v-list-item-icon>
                          <v-icon
                            class="mr-3"
                            v-text="icons.mdiSortAscending"
                          />
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="'ASC'"
                          />
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item value="DESC">
                        <v-list-item-icon>
                          <v-icon
                            class="mr-3"
                            v-text="icons.mdiSortDescending"
                          />
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="'DESC'"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-badge>
            </v-col>

            <!-- Filter Button -->
            <v-col
              cols="12"
              lg="2"
              md="auto"
            >
              <v-badge
                :value="filterCount > 0"
                bordered
                overlap
                class="w-100 cursor-pointer"
                @click.native="clearFilter"
              >
                <template
                  #badge
                >
                  <v-icon>
                    {{ icons.mdiClose }}
                  </v-icon>
                </template>
                <v-btn
                  outlined
                  block
                  large
                  :text="!filterCount"
                  :color="filterCount > 0 ? 'primary' : null"
                  @click.stop="$refs.jobFilterBuilder.show(customAttributesArray)"
                >
                  Filter Job {{ filterCount > 0 ? `(${filterCount})` : null }}
                </v-btn>
              </v-badge>
            </v-col>

            <!-- View Switcher -->
            <v-col
              cols="12"
              md="auto"
              class="ms-0 ms-md-auto d-flex justify-space-between"
            >
              <v-btn-toggle
                v-model="viewTab"
                mandatory
                @change="handleViewChange"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiViewDashboardVariant }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Tampilan Kanban</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiTable }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Tampilan Tabel</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiCardText }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Tampilan Kartu</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiCalendarMonth }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Tampilan Gantt Chart</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card>
        <div
          :key="2"
          style="height: 4px"
        >
          <v-progress-linear
            v-show="loading"
            indeterminate
            color="primary"
          />
        </div>
      </xyztransitiongroup>
    </template>
    <template v-else>
      <v-skeleton-loader
        type="article, actions, table-thead"
        class="mb-6"
      />
    </template>

    <template v-if="!loadingAll">
      <v-card>
        <v-tabs-items
          v-model="viewTab"
          class="rounded-lg"
          touchless
        >
          <!-- Kanban View -->
          <v-tab-item>
            <job-kanban-view
              v-if="viewTab === 0 && jobTypeList[activeTab]"
              :key="3"
              ref="jobKanbanView"
              :types="jobTypeList[activeTab]"
              :jobs.sync="jobList"
              :options.sync="state.jobFilter.value.pagination"
              :items-per-page="10"
              :server-items-length.sync="jobCount"
              @showtask="$refs.taskForm.show($event.customer, $event.job)"
              @delete="deleteJob($event)"
              @update="$refs.jobForm.update($event)"
              @pagination="state.jobFilter.value.pagination = $event"
              @refetch="fetchJobs()"
              @add="$refs.jobForm.show(jobTypeDetail.id, $event)"
            />
          </v-tab-item>

          <!-- Syncfusion Grid -->
          <v-tab-item>
            <job-grid
              v-if="jobList"
              ref="jobGrid"
              :job-list="jobList"
              :custom-attributes-array="customAttributesArray"
              @createtask="$refs.taskForm.show($event.customer, $event)"
              @edit="$refs.jobForm.update($event)"
              @delete="deleteJob($event)"
            />
          </v-tab-item>

          <!-- Card View -->
          <v-tab-item class="mx-4 mt-5">
            <v-data-iterator
              :items="jobList"
              :items-per-page="20"
              item-key="id"
              disable-sort
              :footer-props="{
                'items-per-page-options': [20, 40, 60],
                'items-per-page-text': 'Jumlah data',
              }"
              :loading="loading"
            >
              <template v-slot:default="props">
                <v-row class="match-height">
                  <v-col
                    v-for="item in props.items"
                    :key="item.id"
                    cols="12"
                    sm="6"
                    md="4"
                    xl="3"
                  >
                    <job-card
                      :data="item"
                      @showtask="$refs.taskForm.show(item.customer, item)"
                      @delete="deleteJob($event)"
                      @update="$refs.jobForm.update($event)"
                      @refetch="fetchJobs()"
                    />
                  </v-col>
                </v-row>
              </template>
              <template
                #footer.page-text="page"
              >
                Melihat {{ page.pageStart }} - {{ page.pageStop }} dari total {{ page.itemsLength }} data
              </template>
            </v-data-iterator>
          </v-tab-item>

          <!-- Syncfusion Gantt -->
          <v-tab-item class="gantt-container">
            <job-gantt
              v-if="jobList"
              ref="jobGantt"
              :job-list="jobList"
              @jobEdit="$refs.jobForm.update($event)"
              @taskEdit="$refs.taskForm.update($event)"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <job-type-form
        ref="typeForm"
        @success="getJobTypeData()"
      />

      <job-form
        ref="jobForm"
      />

      <task-form ref="taskForm" />

      <edit-job-type-permission-form
        ref="editFolderPermissionForm"
      />

      <job-filter-builder
        v-if="jobTypeDetail"
        ref="jobFilterBuilder"
        :status="jobTypeDetail.statuses"
        :priority="jobTypeDetail.priorities"
        @filter="fetchJobs"
      />

      <job-sort
        v-if="jobTypeDetail"
        ref="jobSort"
        @sort="fetchJobs"
      />
    </template>
    <template v-else>
      <v-skeleton-loader
        type="table-thead, table-tbody, table-tfoot"
      />
    </template>
  </div>
</template>

<script>
import {
  ref, computed, onMounted, watch,
} from '@vue/composition-api'
import {
  mdiMagnify, mdiPlus, mdiClose, mdiTable, mdiCardText, mdiRefresh, mdiArchive,
  mdiViewDashboardVariant, mdiPencil, mdiDotsVertical, mdiAccountPlus, mdiMenuDown,
  mdiLockOutline, mdiCalendarMonth, mdiChevronDown, mdiSortAscending, mdiSortDescending,
} from '@mdi/js'
import { useStorage, useDebounceFn } from '@vueuse/core'
import { createFieldMapper } from 'vuex-use-fields'
import { ellipsis, avatarText } from '@core/utils/filter'
import { apolloClient } from '@/vue-apollo'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import JobTypeForm from '@/views/job/components/JobTypeForm.vue'
import JobCard from '@/views/job/components/JobCard.vue'
import errorHandling from '@/utils/errorHandling'
import dateFormat from '@/utils/dateFormat'
import TaskForm from '@/views/activity/task/TaskForm.vue'
import JobKanbanView from '@/views/job/components/JobKanbanView.vue'
import JobForm from '@/views/job/components/JobForm.vue'
import useJob from '@/composables/useJob'
import store from '@/store'
import jobUpdateSubscription from '@/graphql/subscription/jobUpdateSubscription'
import EditJobTypePermissionForm from '@/views/folder/EditJobTypePermissionForm.vue'
import useFolder from '@/composables/useFolder'
import { jobs } from '@/graphql/queries'
import router from '@/router'
import Vue from 'vue'
import JobTypeFilter from '@/views/folder/JobTypeFilter.vue'
import JobGrid from '@/views/job/components/JobGrid.vue'
import JobGantt from '../job/components/JobGantt.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { enableRipple } from '@syncfusion/ej2-base'
import useCustomAttributes from '@/composables/useCustomAttributes'
import JobFilterBuilder from '@/views/job/components/JobFilterBuilder.vue'
import JobSort from '@/views/job/components/JobSort.vue'
import { firstBy } from 'thenby'

const useFieldJob = createFieldMapper({ getter: 'job/getField', setter: 'job/setField' })

export default {
  components: {
    TaskForm,
    InputIconToggle,
    JobTypeForm,
    JobCard,
    JobKanbanView,
    JobForm,
    EditJobTypePermissionForm,
    JobTypeFilter,
    JobFilterBuilder,
    JobSort,
    JobGrid,
    JobGantt,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  apollo: {
  // Subscriptions
    $subscribe: {
    // When a job is updated
      jobUpdated: {
        query: jobUpdateSubscription,
        // Reactive variables
        variables() {
          return {
            job_type_id: this.jobTypeDetail?.id,
          }
        },
        result({ data }) {
          const update = data.jobUpdateSubscription
          const idx = this.jobList.findIndex(el => el.id === update.id)
          console.log('receiveJob', update)

          if (idx === -1) {
            if (update.job_type.id === this.jobTypeDetail.id && update.actionType === 'add_job') {
              this.jobList.unshift(update)
              this.refreshComponent()
            }

            return
          }

          if (['delete_job', 'archive_job'].includes(update.actionType)) this.jobList.splice(idx, 1)
          else this.jobList.splice(idx, 1, update)

          this.sortJobs()
          this.refreshComponent()
        },
      },
    },
  },
  setup(props) {
    const {
      fetchFolderDetail, loadingDetailFolder, folderDetail,
      folderPermissionGuard,
    } = useFolder()

    const {
      getJobTypes, loadingJobType, jobTypeList,
      fetchJobTypeDetail, loadingJobTypeDetail, jobTypeDetail,
      loadingJob, jobList, jobCount,
      removeJobType, loadingDeleteJobType,
      updateArchiveJobType, loadingArchiveJobType,
      updateUnarchiveJobType,
    } = useJob()

    const {
      customAttributesArray,
      fetchCustomAttributes,
    } = useCustomAttributes({ objectTypeId: 2 })

    const jobFilterBuilder = ref()
    const jobSort = ref()
    const jobForm = ref()

    enableRipple(true) // enable ripple effect on syncfusion components
    const jobGrid = ref()
    const jobGantt = ref()

    const searchFields = [
      {
        field: 'name',
        label: 'Nama',
        value: true,
      },
      {
        field: 'strip_description',
        label: 'Deskripsi',
        value: true,
      },
      {
        field: 'created_by',
        label: 'Dibuat Oleh',
        value: true,
      },
      {
        field: 'customer',
        label: 'Pelanggan',
        value: false,
      },
      {
        field: 'assigned_to',
        label: 'Ditugaskan ke',
        value: false,
      },
      {
        field: 'custom_attribute_values',
        label: 'Custom Field',
        value: false,
      },
    ]

    const isArchiveOpen = ref(false)
    const activeTab = ref(0)
    const viewTab = useStorage('viewTabJobs', 0)
    const loading = ref(false)
    const selectedRows = ref([])
    const headers = ref([
      {
        text: 'Nama',
        value: 'name',
        width: '320px',
      },
      {
        text: 'Prioritas - Job Type - Status',
        value: 'status',
        class: 'w-vdt-280',
      },
      {
        text: 'Pelanggan',
        value: 'customer',
        class: 'w-vdt-200',
      },
      {
        text: 'Ekspektasi Waktu Ditutup',
        value: 'expected_close_date',
        class: 'w-vdt-200',
      },
      {
        text: 'Dibuat Oleh',
        value: 'created_by',
        class: 'w-vdt-200',
      },
      {
        text: 'Ditugaskan ke',
        value: 'assignedTo',
        width: '220px',
      },
      {
        text: '',
        value: 'action',
      },
    ])

    const jobKanbanView = ref(null)
    const { deleteJob: delJob } = useJob()
    const state = {
      ...useFieldJob(['jobFilter', 'activeTabKanban', 'jobTypeFilter', 'jobSort']),
    }
    const isJobTypeFilterOpen = ref(false)

    const jobTypeFilterCount = computed(() => {
      let count = 0
      if (state.jobTypeFilter.value.my_jobType.value !== null) count += 1
      if (state.jobTypeFilter.value.show_archived.value) count += 1
      if (state.jobTypeFilter.value.sort.id !== 1) count += 1

      return count
    })

    const loadingInitial = computed(() => {
      if (loadingDetailFolder.value) return true
      if (loadingJobType.value) return true

      return false
    })

    const loadingAll = computed(() => {
      if (loadingInitial.value) return true
      if (loadingJobTypeDetail.value) return true
      if (loadingJob.value) return true
      if (loading.value) return true

      return false
    })

    const groupOptions = ref([
      {
        label: 'Status',
        field: 'status',
      },
      {
        label: 'Prioritas',
        field: 'priority',
      },
    ])

    const initGroup = [
      {
        label: 'Status',
        field: 'status',
      },
      {
        label: 'Prioritas',
        field: 'priority',
      },
    ]

    const fetchJobs = async () => {
      const filter = state.jobFilter.value
      // console.log(state.jobFilter.value)
      loading.value = true
      apolloClient.query({
        query: jobs,
        fetchPolicy: 'no-cache',
        variables: {
          pagination: {
            offset: 0,
            limit: 300,
          },
          filter: {
            search: filter.search.length ? filter.search : null,
            sort: {
              field: 'id',
              order: 'DESC',
            },
            job_type: filter.types.length ? filter.types : null,
            status: filter.status.length ? filter.status.map(data => ({
              operator: data.operator,
              value: data.value,
              condition: data.condition,
            })) : null,
            created_by: filter.created_by.length ? filter.created_by.map(data => ({
              operator: data.operator,
              value: data.value,
              condition: data.condition,
            })) : null,
            priority: filter.priority.length ? filter.priority.map(data => ({
              operator: data.operator,
              value: data.value,
              condition: data.condition,
            })) : null,
            customer: filter.customer.length ? filter.customer.map(data => ({
              operator: data.operator,
              value: data.value,
              condition: data.condition,
            })) : null,
            assigned_to: filter.assigned_to.length ? filter.assigned_to.map(data => ({
              operator: data.operator,
              match_type: data.match_type,
              value: data.value,
              condition: data.condition,
            })) : null,
            expected_close_date: filter.expected_close_date.length ? filter.expected_close_date.map(data => ({
              operator: data.operator,
              value: data.value,
              condition: data.condition,
            })) : null,
            updated_at: filter.updated_at.length ? filter.updated_at.map(data => ({
              operator: data.operator,
              value: data.value,
              condition: data.condition,
            })) : null,
            search_field: filter.search_field.filter(el => el.value).map(el => el.field),
            custom_filter: filter.custom_filter.length ? filter.custom_filter.map(data => ({
              attribute_id: data.fieldInput.id,
              data_type_id: data.fieldInput.data_type.id,
              operator: data.operator,
              match_type: data.match_type,
              condition: data.condition,
              value: data.value?.toString(),
            })) : null,
          },
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(async result => {
        await fetchCustomAttributes(2, filter.types[0])
        groupOptions.value = [...initGroup, ...customAttributesArray.value.filter(el => el.custom_attribute.data_type.id === 4).map(ca => ({ label: ca.custom_attribute.name, field: ca.custom_attribute.id }))]
        jobList.value = result.data.jobs.jobs
        jobCount.value = result.data.jobs.count
        setTimeout(() => {
          loading.value = false

          // delay before render again
          if (jobKanbanView.value) jobKanbanView.value.renderList()
        }, 100)
      }).catch(err => {
        loading.value = false
        router.push('/error')
        errorHandling(err)
      })
    }

    const getJobTypeData = () => {
      isJobTypeFilterOpen.value = false
      getJobTypes(+props.id, {
        search: state.jobTypeFilter.value.search,
        sort: state.jobTypeFilter.value.sort.value,
        sortType: state.jobTypeFilter.value.sort.sortType,
        show_archived: state.jobTypeFilter.value.show_archived.value,
        my_jobType: state.jobTypeFilter.value.my_jobType.value,
      }).then(result => {
        const activeTabId = activeTab.value >= result.data.jobTypes.length
          ? result.data.jobTypes.length - 1
          : state.activeTabKanban.value
        state.jobFilter.value.types = [result.data.jobTypes[activeTabId].id]
        activeTab.value = state.activeTabKanban.value ?? 0
        fetchJobTypeDetail(jobTypeList.value[activeTabId].id).then(() => {
          console.log('fetchJobTypeDetail')
          fetchJobs()
          jobKanbanView.value.fetchJobStatus(result.data.jobTypes[activeTabId].id)
          jobKanbanView.value.renderList()
        })
      })
    }

    onMounted(() => {
      if (viewTab.value === 0) store.dispatch('job/resetGroup')
      fetchFolderDetail(+props.id)
      getJobTypeData()
    })

    watch(activeTab, value => {
      state.activeTabKanban.value = value
    })

    const clearFilter = () => {
      store.dispatch('job/resetFilter')
      fetchJobs()
    }

    const clearSort = () => {
      store.dispatch('job/resetSort')
      fetchJobs()
    }

    const searchJob = useDebounceFn(() => {
      fetchJobs()
    }, 1000)

    const deleteJob = id => {
      delJob(id).then(() => {
        fetchJobs()
      })
    }

    const filterCount = computed(() => {
      const filter = state.jobFilter.value
      let count = 0

      if (filter.created_by.length) count += 1
      if (filter.assigned_to.length) count += 1
      if (filter.status.length) count += 1
      if (filter.customer.length) count += 1
      if (filter.priority.length) count += 1
      if (filter.updated_at.length) count += 1
      if (filter.expected_close_date.length) count += 1

      return count
    })

    const sortCount = computed(() => state.jobSort.value.length)

    const sortJobs = () => {
      let condition

      if (state.jobSort.value.length === 0) {
        condition = firstBy('lexorank', 'asc')
      } else {
        state.jobSort.value.forEach((sort, index) => {
          const field = sort.field.value
          const order = sort.type.toLowerCase()

          if (index === 0) condition = firstBy((a, b) => (a[field] === null) - (b[field] === null))

          if (!['status', 'priority'].includes(field)) condition = condition.thenBy(field, { ignoreCase: true, direction: order })
          else condition = condition.thenBy((a, b) => (a[field]?.urutan > b[field]?.urutan ? 1 : -1), { direction: order })
        })

        condition = condition.thenBy('lexorank', 'asc')
      }

      jobList.value = jobList.value.sort(condition)
    }

    const confirmArchiveJobType = () => {
      Vue.$dialog({
        title: 'Arsip Pipeline?',
        body: 'Yakin ingin mengarsipkan job type ini?',
      }).then(confirm => {
        if (confirm) {
          updateArchiveJobType(jobTypeDetail.value.id).then(() => {
            getJobTypeData()
          })
        }
      })
    }

    const confirmUnarchiveJobType = () => {
      Vue.$dialog({
        title: 'Arsip Pipeline?',
        body: 'Yakin ingin memulihkan job type ini?',
      }).then(confirm => {
        if (confirm) {
          updateUnarchiveJobType(jobTypeDetail.value.id).then(() => {
            getJobTypeData()
          })
        }
      })
    }

    const confirmDeleteJobType = () => {
      Vue.$dialog({
        title: 'Hapus Pipeline?',
        body: 'Yakin ingin menghapus job type ini?',
      }).then(confirm => {
        if (confirm) {
          removeJobType(jobTypeDetail.value.id).then(() => {
            getJobTypeData()
          })
        }
      })
    }

    const switchJobType = id => {
      if (viewTab.value === 1) jobGrid.value.flag = true
      if (!['status', 'priority'].includes(state.jobFilter.value.group?.field) && viewTab.value === 0) state.jobFilter.value.group = { label: 'Status', field: 'status' }
      fetchJobTypeDetail(id)
      fetchJobs()
    }

    const groupBy = ref(0)

    const groupGrid = () => {
      if (!state.jobFilter.value.group) {
        jobGrid.value.grid.clearGrouping()
        jobGrid.value.grid.clearSorting()

        return
      }

      const groupField = state.jobFilter.value.group.field
      const groupOrder = state.jobFilter.value.groupOrder === 'ASC' ? 'Ascending' : 'Descending'
      const { groupModule, sortModule } = jobGrid.value.grid.ej2Instances

      if (`${groupField}.name` !== groupModule.groupSettings.columns[0]) {
        if (['status', 'priority'].includes(groupField)) {
          groupModule.groupSettings.columns = [`${groupField}.name`]
          jobGrid.value.grid.sortColumn(`${groupField}.name`, groupOrder)
        } else {
          groupModule.groupSettings.columns = [`custom_attribute_values.${groupField}`]
          jobGrid.value.grid.sortColumn(`custom_attribute_values.${groupField}`, groupOrder)
        }

        return
      }

      if (sortModule.direction !== groupOrder) {
        jobGrid.value.grid.sortColumn(`${groupField}.name`, groupOrder)
      }
    }

    const handleViewChange = () => {
      if (!state.jobFilter.value.group && viewTab.value === 0) store.dispatch('job/resetGroup')
      else if (viewTab.value === 1 && state.jobFilter.value.group) state.jobFilter.value.group = undefined
    }

    const refreshComponent = () => {
      if (viewTab.value === 0) jobKanbanView.value.renderList()
      else if (viewTab.value === 1 || jobGrid.value) jobGrid.value.grid.refreshColumns()
    }

    return {
      getJobTypeData,
      jobTypeDetail,
      isArchiveOpen,
      activeTab,
      viewTab,
      jobList,
      jobCount,
      loading,
      selectedRows,
      headers,
      clearFilter,
      jobKanbanView,
      ellipsis,
      deleteJob,
      state,
      dateFormat,
      searchJob,
      filterCount,
      avatarText,
      sortJobs,
      refreshComponent,

      icons: {
        mdiMagnify,
        mdiPlus,
        mdiClose,
        mdiTable,
        mdiCardText,
        mdiViewDashboardVariant,
        mdiPencil,
        mdiDotsVertical,
        mdiRefresh,
        mdiArchive,
        mdiAccountPlus,
        mdiMenuDown,
        mdiLockOutline,
        mdiCalendarMonth,
        mdiChevronDown,
        mdiSortAscending,
        mdiSortDescending,
      },

      jobGantt,
      jobFilterBuilder,

      searchFields,

      loadingDetailFolder,
      folderDetail,

      loadingInitial,
      loadingAll,
      jobTypeList,
      jobForm,

      loadingJobTypeDetail,
      fetchJobs,
      folderPermissionGuard,
      confirmDeleteJobType,
      loadingDeleteJobType,

      confirmArchiveJobType,
      loadingArchiveJobType,

      confirmUnarchiveJobType,
      switchJobType,

      groupBy,
      groupOptions,

      jobGrid,
      jobSort,
      sortCount,
      clearSort,
      groupGrid,

      handleViewChange,

      jobTypeFilterCount,
      isJobTypeFilterOpen,
      customAttributesArray,
    }
  },
}
</script>

<style lang="scss">
</style>
