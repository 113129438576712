<template>
  <div class="job-grid-container">
    <div class="d-flex justify-space-between grid-toolbar subtitle-1">
      <div>
        <v-btn
          tile
          text
          class="ps-6 px-3 font-weight-regular"
          @click="handleToolbarClick('pdf')"
        >
          <v-icon left>
            {{ icons.mdiFilePdfBox }}
          </v-icon>
          Export PDF
        </v-btn>
        <v-btn
          tile
          text
          class="px-3 font-weight-regular"
          @click="handleToolbarClick('excel')"
        >
          <v-icon left>
            {{ icons.mdiFileExcelBox }}
          </v-icon>
          Export Excel
        </v-btn>
        <v-btn
          tile
          text
          class="px-3 font-weight-regular"
          @click="handleToolbarClick('edit')"
        >
          <v-icon left>
            {{ icons.mdiPencil }}
          </v-icon>
          Edit
        </v-btn>
      </div>
      <div>
        <v-btn
          ref="columnChooser"
          tile
          text
          class="px-3 font-weight-regular"
          @click="handleToolbarClick('columns', $refs.columnChooser.$el.getBoundingClientRect())"
        >
          Columns
          <v-icon right>
            {{ icons.mdiChevronDown }}
          </v-icon>
        </v-btn>
        <v-btn
          tile
          text
          class="pe-6 px-3 font-weight-regular"
          @click="handleToolbarClick('saved-views')"
        >
          Saved Views
          <v-icon right>
            {{ icons.mdiTable }}
          </v-icon>
        </v-btn>
      </div>
    </div>
    <ejs-grid
      id="grid"
      ref="grid"
      :columns="columns"
      :data-source="jobList"
      :edit-settings="editSettings"
      :page-settings="pageSettings"
      :allow-paging="true"
      :allow-sorting="true"
      :allow-grouping="true"
      :allow-resizing="true"
      :allow-reordering="true"
      :allow-pdf-export="true"
      :allow-excel-export="true"
      :show-column-chooser="true"
      :allow-row-drag-and-drop="true"
      :context-menu-open="handleContextMenuOpen"
      :action-begin="handleActionBegin"
      :data-bound="dataBound"
      :row-drop="handleLexorank"
      :group-settings="groupSettings"
      :context-menu-items="contextMenuItems"
      height="61.3vh"
    />
    <v-menu
      v-model="menu"
      :position-x="menuX"
      :position-y="menuY"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          @click="$emit('createtask', selectedRow)"
        >
          Buat Task
        </v-list-item>
        <v-divider class="my-1" />
        <v-list-item
          @click="$router.push({ name: 'job-detail', params: { id: selectedRow.id } })"
        >
          Lihat Detail
        </v-list-item>
        <v-list-item
          @click="$emit('edit', selectedRow.id)"
        >
          Ubah Data
        </v-list-item>
        <v-divider class="my-1" />
        <v-list-item
          v-if="$can('del', 'Job')"
          class="error--text"
          @click="$emit('delete', selectedRow.id)"
        >
          Hapus Job
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Vue from 'vue'
import { ref, computed } from '@vue/composition-api'
import {
  Page,
  Sort,
  Group,
  Freeze,
  Resize,
  Reorder,
  PdfExport,
  ExcelExport,
  Edit,
  ColumnChooser,
  LazyLoadGroup,
  ContextMenu,
  RowDD,
} from '@syncfusion/ej2-vue-grids'
import SelectMultipleColumn from '@/views/custom-attributes/SelectMultipleColumn.vue'
import StatusColumn from '@/views/job/columns/StatusColumn.vue'
import CreatedByColumn from '@/views/job/columns/CreatedByColumn.vue'
import CustomerColumn from '@/views/job/columns/CustomerColumn.vue'
import NameColumn from '@/views/job/columns/NameColumn.vue'
import { createFieldMapper } from 'vuex-use-fields'
import { LexoRank } from 'lexorank'
import { useDebounceFn } from '@vueuse/core'

import store from '@/store'
import useLexorank from '@/composables/useLexorank'
import {
  mdiChevronDown, mdiFilePdfBox, mdiTable, mdiPencil,
  mdiFileExcelBox,
} from '@mdi/js'

const useFieldJob = createFieldMapper({ getter: 'job/getField', setter: 'job/setField' })

export default {
  provide: {
    grid: [
      Page,
      Sort,
      Group,
      Freeze,
      Resize,
      Reorder,
      PdfExport,
      ExcelExport,
      Edit,
      ColumnChooser,
      LazyLoadGroup,
      ContextMenu,
      RowDD,
    ],
  },
  props: {
    jobList: {
      type: Array,
      default: () => [],
    },
    customAttributesArray: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const grid = ref()
    const menu = ref(false)
    const menuX = ref(0)
    const menuY = ref(0)
    const selectedRow = ref(null)
    const customAttributesArray = computed(() => props.customAttributesArray)
    const state = { ...useFieldJob(['jobSort', 'jobFilter']) }
    const { updateObjectLexorank } = useLexorank({ objectType: 'job' })

    const statusColumn = () => ({ template: StatusColumn })
    const createdByColumn = () => ({ template: CreatedByColumn })
    const customerColumn = () => ({ template: CustomerColumn })
    const nameColumn = () => ({ template: NameColumn })
    const selectMultipleColumn = () => ({ template: SelectMultipleColumn })

    const columns = ref([
      {
        field: 'name',
        dataTypeId: null,
        headerText: 'Nama',
        width: '350',
        textAlign: 'Left',
        showInColumnChooser: false,
        allowReordering: false,
        allowGrouping: false,
        template: nameColumn,
      },
      {
        field: 'status.name',
        dataTypeId: null,
        headerText: 'Status',
        width: '150',
        textAlign: 'Center',
        template: statusColumn,
      },
      {
        field: 'customer.name',
        dataTypeId: null,
        headerText: 'Pelanggan',
        width: '200',
        textAlign: 'Left',
        template: customerColumn,
      },
      {
        field: 'start_date',
        dataTypeId: null,
        headerText: 'Waktu Mulai',
        width: '220',
        textAlign: 'Left',
        type: 'dateTime',
        format: { type: 'dateTime', format: 'dd MMMM yyyy · hh:mm' },
      },
      {
        field: 'expected_close_date',
        dataTypeId: null,
        headerText: 'Ekspektasi Waktu Ditutup',
        width: '220',
        textAlign: 'Left',
        type: 'dateTime',
        format: { type: 'dateTime', format: 'dd MMMM yyyy · hh:mm' },
      },
      {
        field: 'created_by.name',
        dataTypeId: null,
        headerText: 'Dibuat Oleh',
        width: '200',
        textAlign: 'Left',
        template: createdByColumn,
      },
    ])
    const pageSettings = ref(
      {
        pageSizes: [20, 40, 60],
        pageSize: 20,
        currentPage: 1,
      },
    )
    const editSettings = ref({
      allowEditing: true,
      allowDeleting: true,
      mode: 'Normal',
    })
    const groupSettings = ref({
      showGroupedColumn: true,
      showDropArea: false,
      disablePageWiseAggregates: true,
    })
    const contextMenuItems = ref([
      {
        text: 'Buat Task',
        target: '.e-content',
        id: 'createTask',
        iconCss: mdiTable,
      },
      {
        target: '.e-content',
        separator: true,
      },
      {
        text: 'Lihat Detail',
        target: '.e-content',
        id: 'detail',
        iconCss: mdiTable,
      },
      {
        text: 'Edit',
        target: '.e-content',
        id: 'edit',
        iconCss: mdiTable,
      },
      {
        text: 'Delete',
        target: '.e-content',
        id: 'delete',
        iconCss: mdiTable,
      },
      'AutoFit', 'AutoFitAll',
      'Cancel',
      'FirstPage', 'PrevPage', 'LastPage', 'NextPage',
    ])

    const flag = ref(true)
    const dataBound = () => {
      if (flag.value === true) {
        flag.value = false

        // push custom attributes columns properties
        customAttributesArray.value.forEach(el => {
          if ([1, 2].includes(el.custom_attribute.data_type.id)) {
            columns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              dataTypeId: el.custom_attribute.data_type.id,
            })
          } else if (el.custom_attribute.data_type.id === 3) {
            columns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              type: 'dateTime',
              format: { type: 'dateTime', format: 'dd MMMM yyyy · hh:mm' },
              dataTypeId: el.custom_attribute.data_type.id,
            })
          } else if (el.custom_attribute.data_type.id === 4) {
            columns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              dataTypeId: el.custom_attribute.data_type.id,
            })
          } else if (el.custom_attribute.data_type.id === 5) {
            columns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              allowSorting: false,
              template: selectMultipleColumn,
              dataTypeId: el.custom_attribute.data_type.id,
            })
          }
        })

        // initial grouping
        if (state.jobFilter.value.group) {
          const groupField = state.jobFilter.value.group.field
          const groupOrder = state.jobFilter.value.groupOrder === 'ASC' ? 'Ascending' : 'Descending'

          if (['status', 'priority'].includes(groupField)) {
            grid.value.groupColumn(`${groupField}.name`)
            grid.value.sortColumn(`${groupField}.name`, groupOrder)
          } else {
            grid.value.groupColumn(`custom_attribute_values.${groupField}`)
            grid.value.sortColumn(`custom_attribute_values.${groupField}`, groupOrder)
          }
        }
      }
    }

    const handleContextMenuOpen = args => {
      /* eslint-disable no-param-reassign */
      if (grid.value.getContentTable().contains(args.event.target)) {
        args.cancel = true
        menuX.value = args.left
        menuY.value = args.top
        selectedRow.value = args.rowInfo.rowData
        menu.value = true
      }
      /* eslint-enable no-param-reassign */
    }

    const handleToolbarClick = (action, event) => {
      switch (action) {
        case 'pdf':
          grid.value.pdfExport()
          break
        case 'excel':
          grid.value.excelExport()
          break
        case 'edit':
          emit('edit', grid.value.getSelectedRecords()[0].id)
          break
        case 'columns':
          grid.value.openColumnChooser(event.right - grid.value.$el.getBoundingClientRect().x - 250, 0)
          break

        default:
          break
      }
    }

    const handleActionBegin = args => {
      /* eslint-disable no-param-reassign */
      if (args.requestType === 'beginEdit') {
        args.cancel = true
        emit('edit', args.rowData.id)
      } else if (args.requestType === 'sorting') {
        if (!grid.value.ej2Instances.groupModule.groupSettings.properties.columns.length) args.cancel = true
      }
      /* eslint-enable no-param-reassign */
    }

    const getLexorank = args => {
      const direction = args.dropIndex > args.fromIndex // true = down, false = up
      const dataAbove = props.jobList[direction ? args.dropIndex : args.dropIndex - 1]
      const dataBelow = props.jobList[direction ? args.dropIndex + 1 : args.dropIndex]
      const movedData = args.data[0]

      if (dataAbove && dataBelow) {
        const dataAboveLexorank = LexoRank.parse(dataAbove.lexorank)
        const dataBelowLexorank = LexoRank.parse(dataBelow.lexorank)

        return dataAboveLexorank.between(dataBelowLexorank)
      } if (dataAbove) {
        const dataAboveLexorank = LexoRank.parse(dataAbove.lexorank)

        return dataAboveLexorank.genNext()
      } if (dataBelow) {
        const dataBelowLexorank = LexoRank.parse(dataBelow.lexorank)

        return dataBelowLexorank.genPrev()
      }

      return LexoRank.parse(movedData.lexorank)
    }

    const invalidMoveRank = useDebounceFn(msg => {
      Vue.notify({
        title: 'Tidak Valid',
        text: msg,
        type: 'warn',
      })
    }, 200)

    const handleLexorank = args => {
      if (state.jobSort.value.length) {
        // eslint-disable-next-line no-param-reassign
        args.cancel = true

        invalidMoveRank('Hanya boleh mengubah urutan pada mode sortir RANK')
      } else if (grid.value.ej2Instances.groupModule.groupSettings.properties.columns.length) {
        // eslint-disable-next-line no-param-reassign
        args.cancel = true

        invalidMoveRank('Tidak bisa mengubah urutan pada mode grup')
      } else {
        updateObjectLexorank(getLexorank(args).toString(), args.data[0].id, store.getters.getCurrentWorkspaceId)
      }
    }

    return {
      flag,
      grid,
      menu,
      menuX,
      menuY,
      selectedRow,
      columns,
      pageSettings,
      editSettings,
      groupSettings,
      contextMenuItems,

      handleLexorank,
      dataBound,
      handleActionBegin,
      handleToolbarClick,
      handleContextMenuOpen,

      icons: {
        mdiTable,
        mdiPencil,
        mdiChevronDown,
        mdiFilePdfBox,
        mdiFileExcelBox,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/syncfusion/material/individual-scss/grids/grid.scss';
@import '~@/styles/syncfusion-overrides.scss';

.job-grid-container {
  .e-sortfilterdiv {
    display: none;
  }
}
</style>
