<template>
  <div class="kanban mr-1">
    <div
      class="kanban-column"
      :style="`border-top: 8px ${column.color} solid;`"
    >
      <slot
        name="column-header"
        :data="{
          index,
          columnData: column,
        }"
      >
        <div class="kanban-header">
          <small class="mb-2">{{ column.name }}</small>
        </div>
      </slot>
      <draggable
        v-scroll.self="listenScrollFetchMore"
        :list="column.lists"
        :animation="200"
        :move="checkMove"
        ghost-class="ghost-card"
        group="lists"
        class="kanban-card-content"
        :delay="500"
        :delay-on-touch-only="true"
        @change="updateStatus($event, index)"
      >
        <div
          v-for="list in column.lists"
          :key="list.id"
        >
          <slot
            name="kanban-card"
            :data="list"
          >
            <v-card
              :key="list.id"
              class="mb-2"
            >
              <v-card-text>
                {{ list.name }}
              </v-card-text>
            </v-card>
          </slot>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mdiDotsHorizontal } from '@mdi/js'
import { useDebounceFn } from '@vueuse/core'

export default {
  components: {
    draggable,
  },
  props: {
    column: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    checkMove: {
      type: Function,
      default: null,
    },
  },
  setup(props, { emit }) {
    const listenScrollFetchMore = useDebounceFn(async data => {
      if (
        (data.target.offsetHeight + data.target.scrollTop) >= (data.target.scrollHeight - 400)
      ) {
        emit('fetchMore', props.index)
      }
    }, 200)

    const updateStatus = (data, index) => {
      if (data.added) {
        emit('moveGroup', {
          dragData: data.added,
          index,
        })
      } else if (data.moved) {
        emit('moveRank', {
          dragData: data.moved,
          index,
        })
      }
    }

    return {
      updateStatus,
      listenScrollFetchMore,

      icons: {
        mdiDotsHorizontal,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';

.kanban {
  .kanban-column {
    min-width: 340px;
    width: 340px;
    background: rgba(255, 255, 255, 0.8);
    padding: 0 12px;
    border-right: 1px rgb(215, 215, 215) solid;
    overflow-x: hidden;
    border-bottom: 1px rgb(215, 215, 215) solid;

    .kanban-header {
      padding: 10px 4px;
      font-weight: 600;
    }

    .ghost-card {
      background: rgb(240, 240, 240) !important;
    }

    .theme--dark & {
      background: map-deep-get($material-dark, "chips");
      border-right: 1px map-deep-get($material-dark, "dividers") solid;
      border-bottom: 1px map-deep-get($material-dark, "dividers") solid;
    }

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: 1px rgb(215, 215, 215) solid;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    .theme--dark & {
      border-left: 1px map-deep-get($material-dark, "dividers") solid;
    }
  }

  .kanban-card-content {
    @include style-scroll-bar();
    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }
    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      background-clip: padding-box;
    }
    min-height: 326px;
    max-height: 69vh;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-right: -12px;
  }
}

.theme--dark {
  .kanban-column {
    background: rgba(255, 255, 255, 0.1);

    .ghost-card {
      background: rgba(255, 255, 255, 0.1) !important;
      border-radius: 6px;
    }
  }
}
</style>
